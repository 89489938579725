.page-status {
	padding: 5px 15px;
	border-radius: var(--wndr-border-radius);
	color: var(--primary-contrast-color);
	white-space: nowrap;
}

.page-status_requested {
	background-color: #ffa629;
}

.page-status_accepted {
	background-color: var(--success-color);
}

.page-status_declined {
	background-color: var(--error-color);
}

.page-status_in-progress {
	background-color: var(--success-color);
}

.page-status_completed {
	background-color: var(--secondary-color);
}

.page-status_cancelled {
	background-color: var(--error-color);
}

.page-status_paid {
	background-color: var(--primary-dark-color);
}

.page-status_pending {
	background-color: var(--ink-primary-color);
}
