.wndr-button.secondary {
	--text-color: var(--secondary-contrast-color);
	--surface-color: var(--secondary-color);
}

.wndr-button.secondary:active {
	--surface-color: var(--secondary-dark-color);
}

.wndr-button.secondary:disabled {
	--surface-color: var(--wndr-disabled-button-color);
}
