.action-sheet-button-inner,
.action-sheet-title {
	/* Overriding ionic styles. */
	/* stylelint-disable declaration-no-important */

	font-size: var(--subtitle-1-font-size) !important;
	color: var(--primary-dark-color) !important;
	text-align: center !important;

	/* stylelint-enable declaration-no-important */
}

.action-sheet-button-inner.sc-ion-action-sheet-md {
	justify-content: center;
}

.action-sheet-button.sc-ion-action-sheet-md,
.action-sheet-title.sc-ion-action-sheet-md {
	border-bottom: var(--wndr-border);
}
