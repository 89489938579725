:root {
	--success-active-button-color: #68ab67;
}

.wndr-button.success {
	--text-color: var(--success-contrast-color);
	--surface-color: var(--success-color);
}

.wndr-button.success:active {
	--surface-color: var(--success-active-button-color);
}

.wndr-button.success:disabled {
	--surface-color: var(--success-color);
}
