.wndr-button.outline {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 15px 20px;
	background: transparent;
	border: 1px solid var(--surface-color);
	color: var(--text-color);
	font-weight: normal;
}

.wndr-button.outline:active {
	background: transparent;
}
