:root {
	--wndr-large-button-size: 48px;
	--wndr-large-button-font-size: 18px;
}

.wndr-button.large {
	padding: 15px;
}

.wndr-button.wndr-icon-button.large {
	padding: 0;
	width: var(--wndr-large-button-size);
	height: var(--wndr-large-button-size);
	font-size: var(--wndr-large-button-font-size);
}

.wndr-button.wndr-icon-button.large ion-icon {
	font-size: var(--wndr-large-button-font-size);
}

/* Tablet & Desktop. */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
	only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape),
	only screen and (min-width: 1025px) {
	:root {
		--wndr-large-button-size: 66px;
		--wndr-large-button-font-size: 30px;
	}
}
