:root {
	--warn-active-button-color: #d05858;
}

.wndr-button.warn {
	--text-color: var(--text-contrast-color);
	--surface-color: var(--error-color);
}

.wndr-button.warn:active {
	--surface-color: var(--warn-active-button-color);
}

.wndr-button.warn:disabled {
	--surface-color: var(--wndr-disabled-button-color);
}
