:root {
	--tabs-height: 73px;

	--footer-height: 99px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
	only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	:root {
		--tabs-height: 95px;
	}
}

@media only screen and (min-width: 1025px) and (orientation: landscape) {
	:root {
		--tabs-height: 10px;
	}
}
