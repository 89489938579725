:root {
	--wndr-medium-button-size: 30px;
	--wndr-medium-button-font-size: 16px;
}

.wndr-button.wndr-icon-button.medium {
	padding: 0;
	width: var(--wndr-medium-button-size);
	height: var(--wndr-medium-button-size);
	font-size: var(--wndr-medium-button-font-size);
}

.wndr-button.wndr-icon-button.medium ion-icon {
	font-size: var(--wndr-medium-button-font-size);
}

/* Tablet & Desktop. */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
	only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape),
	only screen and (min-width: 1025px) {
	:root {
		--wndr-medium-button-size: 48px;
		--wndr-medium-button-font-size: 24px;
	}
}
