:root {
	--wndr-small-button-size: 24px;
	--wndr-small-button-font-size: 18px;
}

.wndr-button.wndr-icon-button.small {
	width: var(--wndr-small-button-size);
	height: var(--wndr-small-button-size);
	font-size: var(--wndr-small-button-font-size);
}

.wndr-button.wndr-icon-button.small ion-icon {
	font-size: var(--wndr-small-button-font-size);
}
