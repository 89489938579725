.cdk-overlay-pane.mat-mdc-dialog-panel {
	--mat-dialog-container-min-width: 600px;
	--mat-dialog-container-max-width: 600px;

	--mat-dialog-headline-padding: 10px 10px 5px;
	--mat-dialog-content-padding: 30px;
	--mat-dialog-with-actions-content-padding: 30px;
	--mat-dialog-actions-padding: 10px 30px 30px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	overflow: visible;
}

ion-modal .ion-page {
	position: relative;
	contain: layout style;
	height: 100%;
}

ion-modal.popup {
	--height: unset;
}

ion-modal.fullscreen {
	--background: transparent;
	--width: 100%;
	--height: 100%;
}

ion-modal.no-opacity {
	/* stylelint-disable-next-line declaration-no-important */
	--backdrop-opacity: 0 !important;
}

ion-modal.with-opacity {
	/* stylelint-disable-next-line declaration-no-important */
	--backdrop-opacity: 0.7 !important;
}

ion-modal.fit-content {
	--width: fit-content;
	--height: unset;
}

@media (max-width: 599px) {
	.cdk-overlay-pane.mat-mdc-dialog-panel {
		--mat-dialog-container-min-width: 100vw;
		--mat-dialog-container-max-width: 100vw;
		--mat-dialog-container-small-max-width: 100vw;

		/* Border radius */
		--mdc-dialog-container-shape: 0px;

		height: 100%;
	}

	.cdk-overlay-pane.mat-mdc-dialog-panel .mat-mdc-dialog-content {
		max-height: unset;
	}

	.mat-mdc-dialog-container .mdc-dialog__surface {
		overflow: auto;
	}

	ion-modal.popup {
		--width: 95%;
	}
}

ion-modal.confirmation-dialog {
	--width: min(500px, 100%);
	--height: fit-content;
	padding: 0 10px;
}

ion-modal.session-request-sent-modal,
ion-modal.session-accepted-modal {
	--width: min(500px, 90%);
	--height: fit-content;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
	ion-modal.attachment-viewer-modal {
		--width: min(1200px, 100%);
		--height: min(800px, 100%);
	}
}
