.mat-mdc-slide-toggle {
	--unselected-toggle-background-color: #e06666;
	--selected-toggle-background-color: #73bf72;

	--mdc-switch-unselected-track-color: var(--unselected-toggle-background-color);
	--mdc-switch-unselected-focus-track-color: var(--unselected-toggle-background-color);
	--mdc-switch-unselected-hover-track-color: var(--unselected-toggle-background-color);
	--mdc-switch-unselected-pressed-track-color: var(--unselected-toggle-background-color);

	--mdc-switch-selected-track-color: var(--selected-toggle-background-color);
	--mdc-switch-selected-focus-track-color: var(--selected-toggle-background-color);
	--mdc-switch-selected-hover-track-color: var(--selected-toggle-background-color);
	--mdc-switch-selected-pressed-track-color: var(--selected-toggle-background-color);
}
