.visually-hidden {
	/*
    To preserve a11y, hide radio button only visually
    https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  */
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	margin: 0;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.no-margin {
	margin: 0;
}

.paddings {
	padding: var(--wndr-paddings);
}

.side-paddings {
	padding: 0 var(--wndr-paddings);
}

.no-paddings {
	padding: 0;
}

.break-word {
	word-wrap: break-word;
}

.bold {
	font-weight: 600;
}

.light {
	font-weight: 300;
}

.text-center {
	text-align: center;
}

.hover-highlight:hover {
	background-color: var(--tertiary-extra-light-color);
}
