.wndrc-badge {
	--badge-size: 20px;
	--badge-font-size: 10px;
	--badge-notification-background-color: #e06666;
	--badge-notification-text-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--badge-size);
	height: var(--badge-size);
	position: absolute;
	top: -8px;
	right: -8px;
	font-weight: initial;
	font-size: var(--badge-font-size);
	color: var(--badge-notification-text-color);
	background-color: var(--badge-notification-background-color);
	border-radius: 50%;
	text-align: center;
	line-height: 1;
	white-space: nowrap;
}
