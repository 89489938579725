.heading-1 {
	font-size: var(--h1-font-size);
	font-weight: 600;
	line-height: 1.1;
	color: var(--primary-color);
	margin: 0;
}

.heading-2 {
	color: var(--text-color);
	font-size: var(--h2-font-size);
	font-weight: 600;
	line-height: 1.5;
	margin: 0;
}

.subtitle-1 {
	color: var(--text-color);
	font-size: var(--subtitle-1-font-size);
	line-height: 1.7;
	font-weight: 300;
	margin: 0;
}

.subtitle-2 {
	font-size: var(--subtitle-2-font-size);
	font-weight: 600;
	line-height: 1.38;
	margin: 0;
}

.body-1 {
	font-size: var(--body-1-font-size);
	font-weight: 400;
	line-height: var(--body-1-line-height);
	color: var(--primary-color);
	margin: 0;
}

.body-2 {
	font-size: var(--body-2-font-size);
	font-weight: 400;
	line-height: 1.55;
	color: var(--text-color);
	margin: 0;
}

.caption {
	font-size: var(--caption);
	opacity: 0.6;
	line-height: 1.45;
	margin: 0;
}

.bold {
	font-weight: 600;
}

.normal {
	font-weight: 400;
}

.uppercase {
	text-transform: uppercase;
}
