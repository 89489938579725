.snackbar.snackbar_warning {
	--mdc-snackbar-container-color: var(--error-light-color);
	--mdc-snackbar-supporting-text-color: var(--error-color);
}

.snackbar.snackbar_success {
	--mdc-snackbar-container-color: var(--success-color);
	--mdc-snackbar-supporting-text-color: var(--success-contrast-color);
}

.mdc-snackbar.snackbar {
	margin-top: calc(15px + env(safe-area-inset-top, 0));
}
