.mat-mdc-menu-panel {
	border: var(--wndr-border);
	border-radius: var(--wndr-border-radius);
}

.mat-mdc-menu-item {
	--mat-menu-item-leading-spacing: 0;
	--mat-menu-item-trailing-spacing: 0;
}

.mat-mdc-menu-panel .mat-mdc-menu-content {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 15px 20px;
}

.mat-mdc-menu-content .mat-menu-title {
	margin: 0;
	font-weight: 600;
}

.mat-mdc-menu-content .mat-mdc-menu-item {
	min-height: auto;
	padding: 10px 0;
	border-bottom: 1px dashed var(--wndr-border-color);
}

.mat-mdc-menu-content .mat-mdc-menu-item:not([disabled]):hover {
	background-color: transparent;
}

.mat-mdc-menu-item .mat-mdc-menu-ripple {
	display: none;
}
