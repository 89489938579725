.session-status {
	padding: 5px 15px;
	border-radius: var(--wndr-border-radius);
	color: var(--primary-contrast-color);
	white-space: nowrap;
}

.session-status_requested {
	background-color: #ffa629;
}

.session-status_accepted {
	background-color: #14ae5c;
}

.session-status_declined {
	background-color: var(--error-color);
}

.session-status_in-progress {
	background-color: #14ae5c;
}

.session-status_completed {
	background-color: var(--secondary-color);
}

.session-status_cancelled {
	background-color: var(--error-color);
}

.session-status_paid {
	background-color: black;
}
