@import "./fonts.css";
@import "./variables.css";
@import "./elements/index.css";
@import "./typography.css";
@import "./util.css";
@import "./animations.css";
@import "./directives.css";

* {
	box-sizing: border-box;
	font-size: var(--body-1-font-size);
	line-height: var(--body-1-line-height);
	font-family:
		"Graphik Web",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		"Open Sans",
		"Helvetica Neue",
		sans-serif;
}

html,
body {
	min-height: 100%;
	background: var(--background-color);
	color: var(--background-contrast-color);
	margin: 0;
}

sup {
	display: inline-block;
}

/* Global animations */
@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.cdk-overlay-container {
	/* stylelint-disable-next-line declaration-no-important */
	z-index: 50000 !important;
}

.call-dialog-backdrop {
	background-color: rgba(0, 0, 0, 0.5);
}

.call-dialog-backdrop.hidden {
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	background-color: transparent;
}

.call-dialog-panel {
	background-color: white;
	padding: 30px;
	border-radius: 20px;
}

.call-dialog-panel.hidden {
	display: none;
}

@media (max-width: 1024px) {
	.call-dialog-panel {
		width: 100vw;
		height: 100vh;
		border-radius: 0;
		padding-bottom: 50px;
	}
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background: var(--scrollbar-track-color);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb {
	background: var(--scrollbar-thumb-color);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--scrollbar-thumb-hover-color);
}
