:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */

	--primary-color: #222222;
	--primary-dark-color: #000000;
	--primary-contrast-color: #ffffff;

	--secondary-color: #285fcf;
	--secondary-light-color: #8bd0f695;
	--secondary-dark-color: #143f94;
	--secondary-contrast-color: #ffffff;

	--error-color: #e06666;
	--error-contrast-color: #000000;
	--error-light-contrast-color: #ffffff;
	--error-light-color: #fae8e8;

	--tertiary-color: #6b6044;
	--tertiary-light-color: #efe5cc;
	--tertiary-extra-light-color: #fdf8ec;

	--success-color: #73bf72;
	--success-contrast-color: #ffffff;

	--ink-primary-color: #cccccc;

	--scrollbar-track-color: #f1f1f1;
	--scrollbar-thumb-color: #888888;
	--scrollbar-thumb-hover-color: #555555;

	/* Use prefix because ion-modal has it's own custom properties with the same names.*/
	--wndr-border-color: #cccccc;
	--wndr-disabled-button-color: #cccccc;
	--wndr-border-width: 1px;
	--wndr-border-style: solid;
	--wndr-border: var(--wndr-border-width) var(--wndr-border-style) var(--wndr-border-color);
	--wndr-border-radius: 23px;
	--wndr-background: url(/assets/figures-background.svg) 100% / 100% no-repeat fixed;
	--wndr-paddings: 16px;

	--text-color: #000000;
	--text-contrast-color: #ffffff;
	--placeholder-color: rgba(0, 0, 0, 0.5);

	--surface-color: #cccccc;

	--badge-notification-background-color: #e06666;
	--badge-notification-text-color: #ffffff;

	/* Layout. */
	--max-screen-width: 1280px;
	--type-message-height: 99px;
	--message-attachments-height: 52px;

	/** Typography */
	--button-font-size: 16px;
	--h1-font-size: 26px;
	--h2-font-size: 18px;
	--subtitle-1-font-size: 18px;
	--subtitle-2-font-size: 16px;
	--body-1-font-size: 14px;
	--body-1-line-height: 1.45;
	--body-2-font-size: 13px;
	--caption: 12px;
	--modal-title-font-size: 16px;

	/* Spacing */
	--page-padding-top: 30px;
	--page-padding-bottom: 30px;
	--page-padding-x: 30px;
	--page-padding: var(--page-padding-top) var(--page-padding-x) var(--page-padding-bottom);

	--space-m: 30px;
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
	only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	:root {
		--button-font-size: 24px;
		--h1-font-size: 26px;
		--h2-font-size: 28px;
		/* TODO: update value when we know what size is used for tablet / desktop.*/
		--subtitle-1-font-size: 18px;
		--subtitle-2-font-size: 26px;
		--body-1-font-size: 24px;
		--caption: 20px;
		--modal-title-font-size: 24px;
		--body-2-font-size: 22px;

		/* Spacing */
		--page-padding-top: 40px;
		--page-padding-bottom: 40px;
		--page-padding-x: 40px;

		--type-message-height: 160px;
	}
}

/* Desktop */
@media only screen and (min-width: 1025px) {
	:root {
		--button-font-size: 14px;
		--h1-font-size: 30px;
		--h2-font-size: 24px;
		/* TODO: update value when we know what size is used for tablet / desktop.*/
		--subtitle-1-font-size: 18px;
		--subtitle-2-font-size: 20px;
		--body-1-font-size: 14px;
		--caption: 16px;
		--modal-title-font-size: 20px;
		--body-2-font-size: 13px;

		--type-message-height: 85px;
	}
}
