.wndr-button.primary {
	--text-color: var(--primary-contrast-color);
	--surface-color: var(--primary-color);
}

.wndr-button.primary:active {
	--surface-color: var(--primary-dark-color);
}

.wndr-button.primary:disabled {
	--surface-color: var(--wndr-disabled-button-color);
}

.wndr-button.primary.outline {
	--text-color: var(--primary-dark-color);
	--surface-color: var(--ink-primary-color);
}
