/* stylelint-disable font-family-no-missing-generic-family-keyword */
/*
  This font software is the property of Commercial Type.

  You may not modify the font software, use it on another website, or install it on a computer.

  License information is available at http://commercialtype.com/eula
  For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

  Copyright (C) 2022 Schwartzco Inc.
  License: 2204-NHXCPU     
*/

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-SuperItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-SuperItalic-Web.woff") format("woff");
	font-weight: 900;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Super-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Super-Web.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-BlackItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-BlackItalic-Web.woff") format("woff");
	font-weight: 800;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Black-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Black-Web.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-BoldItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-BoldItalic-Web.woff") format("woff");
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Bold-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Bold-Web.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-SemiboldItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-SemiboldItalic-Web.woff") format("woff");
	font-weight: 600;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Semibold-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Semibold-Web.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-MediumItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-MediumItalic-Web.woff") format("woff");
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Medium-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Medium-Web.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-RegularItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-RegularItalic-Web.woff") format("woff");
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Regular-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Regular-Web.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-LightItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-LightItalic-Web.woff") format("woff");
	font-weight: 300;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Light-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Light-Web.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-ExtralightItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-ExtralightItalic-Web.woff") format("woff");
	font-weight: 200;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Extralight-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Extralight-Web.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-ThinItalic-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-ThinItalic-Web.woff") format("woff");
	font-weight: 100;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: "Graphik Web";
	src:
		url("../../../assets/fonts/Graphik-Thin-Web.woff2") format("woff2"),
		url("../../../assets/fonts/Graphik-Thin-Web.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
}
