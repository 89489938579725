button {
	/*
		Basically, apple is adding the blue color to every button.
		There is no way to avoid it without overriding.
		Inherit the text color from the body to set app's colors for the unstylish buttons.
		*/
	color: inherit;
}

/*
	Using prefix, due applying styles just for `.button` styles
	may override ionic elements like `ion-back-button`.
	*/
button.wndr-button,
a.wndr-button {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	text-decoration: none;
	border: none;
	cursor: pointer;
	border-radius: var(--wndr-border-radius);
	font-size: var(--button-font-size);
	font-weight: 700;
	line-height: 1;
}

.wndr-button:disabled {
	cursor: not-allowed;
}
