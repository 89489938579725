ngx-emoji.ngx-emoji-inline {
	display: flex;
	align-items: center;
	justify-content: center;
}

.emoji-mart-modal {
	display: block;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}

.emoji-mart-modal .emoji-mart {
	/* To override inline styles. */
	/* stylelint-disable-next-line declaration-no-important */
	width: 100% !important;
}

.emoji-mart-modal .emoji-mart-scroll {
	height: 100%;
}
