mat-select .mat-mdc-select-trigger {
	border-radius: var(--wndr-border-radius);
	border: var(--wndr-border);
	padding: 0 20px;
	padding-right: 27px;
	height: 48px;
}

mat-select.ng-invalid.ng-touched .mat-mdc-select-trigger {
	border-color: var(--error-color);
}

mat-select .mat-mdc-select-placeholder {
	font-size: var(--body-1-font-size);
	letter-spacing: normal;
}

mat-select.mat-select-without-arrow .mat-mdc-select-trigger {
	padding-right: 20px;
}

mat-select.mat-select-without-arrow .mat-mdc-select-arrow-wrapper {
	display: none;
}

mat-select mat-select-trigger {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
